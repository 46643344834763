import { Col } from "antd";
import React from "react";
import { useEffect } from "react";

import * as classes from "./Coaches.module.scss";

const CoachCard = (props) => {
  const { coach } = props;

  useEffect(() => {
    setInterval(() => {
      const caseStudyItems = document.getElementsByClassName(classes.coach);
      let maxEleHeight = 0;
      for (let i = 0; i < caseStudyItems.length; i++) {
        if (caseStudyItems[i].offsetHeight > maxEleHeight) {
          maxEleHeight = caseStudyItems[i].offsetHeight;
        }
      }
      for (let i = 0; i < caseStudyItems.length; i++) {
        caseStudyItems[i].style.height = `${maxEleHeight}px`;
      }
    }, 1000);
  }, []);

  return (
    <Col className={classes.coach}>
      <img src={coach.imageUrl} alt="Unschool Skill Courses" />
      <Col className={classes.coachDetails}>
        <Col>
          <h3 className={classes.coachName}>{coach.name}</h3>
          <h3 className={classes.course}>{coach.course}</h3>
        </Col>
        {coach?.companyLogo && (
          <img
            className={classes.companyLogo}
            src={coach.companyLogo}
            alt="Unschool Skill Courses"
          />
        )}
      </Col>
    </Col>
  );
};

export default CoachCard;
