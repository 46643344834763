// extracted by mini-css-extract-plugin
export var container = "Reviews-module--container--fef70";
export var controlButtons = "Reviews-module--controlButtons--5a3d6";
export var lmsScrollbar = "Reviews-module--lms-scrollbar--21f78";
export var reviewCard = "Reviews-module--reviewCard--bd29f";
export var reviewCardBottom = "Reviews-module--reviewCardBottom--f6f0c";
export var reviewCardTop = "Reviews-module--reviewCardTop--ade42";
export var reviewerCard = "Reviews-module--reviewerCard--54455";
export var reviewers = "Reviews-module--reviewers--473cc";
export var reviewersContainer = "Reviews-module--reviewersContainer--c2d16";
export var reviewsDesktop = "Reviews-module--reviewsDesktop--11783";
export var reviewsTablet = "Reviews-module--reviewsTablet--549b8";
export var wrapper = "Reviews-module--wrapper--508df";