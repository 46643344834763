import React from "react";

import Button from "./Button";
import {
  Businesssection,
  sectiontitle,
  sectionlogo,
  sectioncontent,
  sectioncontentleft,
  sectioncontentlefttitle,
  sectioncontentleftcontent,
  sectioncontentright,
  sectioncontentlefttitleimg,
} from "./styles/business.module.css";

import {
  SKILL_FINANCEPEER_LOGO,
  SKILL_UNSCHOOL_DARK_LOGO,
  SKILL_GLOCAL_LOGO,
  SKILL_NASCOM_LOGO,
  SKILL_SKILL_LOGO,
  SKILL_SPRING_LOGO,
  SKILL_TASK_LOGO,
  SKILL_THUB_LOGO,
  SKILL_WOMEN_LOGO,
} from "../../utils/localization";
const Business = () => {
  return (
    <div className={Businesssection}>
      <div className={sectiontitle}>
        <h2>We’re trusted, backed and powered by the best in the industry</h2>
      </div>
      <div className={sectionlogo}>
        <img
          src={SKILL_FINANCEPEER_LOGO}
          placeholder="blurred"
          alt="Linkedin"
        />
        <img
          src={SKILL_GLOCAL_LOGO}
          placeholder="blurred"
          alt="Unschool Skill Courses"
        />
        <img
          src={SKILL_NASCOM_LOGO}
          placeholder="blurred"
          alt="Unschool Skill Courses"
        />
        <img
          src={SKILL_SKILL_LOGO}
          placeholder="blurred"
          alt="Unschool Skill Courses"
        />
        <img
          src={SKILL_SPRING_LOGO}
          placeholder="blurred"
          alt="Unschool Skill Courses"
        />
        <img
          src={SKILL_TASK_LOGO}
          placeholder="blurred"
          alt="Unschool Skill Courses"
        />
        <img
          src={SKILL_THUB_LOGO}
          placeholder="blurred"
          alt="Unschool Skill Courses"
        />
      </div>
      <div className={sectioncontent}>
        <div className={sectioncontentleft}>
          <div className={sectioncontentlefttitle}>
            <img
              src={SKILL_UNSCHOOL_DARK_LOGO}
              className={sectioncontentlefttitleimg}
              alt="Unschool Skill Courses"
            />
            <span>for Business</span>
          </div>
          <div className={sectioncontentleftcontent}>
            <p>
              Get unlimited access to 200+ of Unschool’s top courses for your
              team. Learn and improve skills across business, tech, design, and
              more.
            </p>
          </div>
          <Button
            source={
              "mailto:admin@unschool.in?subject=Business with unschool &body=Hello Unschool Team,%0D%0A %0D%0A This email is to request you for a call reagarding Unschool for business courses. I have a team of < add number > employees that have expertise in < your domain >. I wish to expand my team's knowledge in the field of < upskill field >. Our Company's major aim to take up these courses is to < reason >. I will await the calendar invite from the Unschool team to discuss further process. %0D%0A %0D%0A Thankyou! "
            }
            text={"Get Unschool Business"}
          />
        </div>
        <div className={sectioncontentright}>
          <img src={SKILL_WOMEN_LOGO} alt="Unschool Skill Courses" />
        </div>
      </div>
    </div>
  );
};

export default Business;
