// extracted by mini-css-extract-plugin
export var accordianHeader = "Faq-module--accordianHeader--165e1";
export var accordianItem = "Faq-module--accordianItem--3803c";
export var accordianText = "Faq-module--accordianText--24085";
export var contactUnschool = "Faq-module--contactUnschool--702fb";
export var courseFaqContainer = "Faq-module--courseFaqContainer--70dce";
export var courseFaqContent = "Faq-module--courseFaqContent--605f1";
export var courseFaqContentLeft = "Faq-module--courseFaqContentLeft--87e32";
export var courseFaqContentRight = "Faq-module--courseFaqContentRight--9dbb1";
export var faqImageContainer = "Faq-module--faqImageContainer--d6ffc";
export var headingContainer = "Faq-module--headingContainer--57957";
export var headingDesktop = "Faq-module--headingDesktop--e5880";
export var headingMobile = "Faq-module--headingMobile--85880";
export var iconClassName = "Faq-module--iconClassName--6adaa";
export var lmsScrollbar = "Faq-module--lms-scrollbar--1b700";