// extracted by mini-css-extract-plugin
export var bill = "SkillCoursePurchaseSuccessModal-module--bill--1a22c";
export var billAmount = "SkillCoursePurchaseSuccessModal-module--billAmount--224e5";
export var billContent = "SkillCoursePurchaseSuccessModal-module--billContent--a7393";
export var billItem = "SkillCoursePurchaseSuccessModal-module--billItem--30d08";
export var billTitle = "SkillCoursePurchaseSuccessModal-module--billTitle--75ed8";
export var billTotal = "SkillCoursePurchaseSuccessModal-module--billTotal--2cd0a";
export var itemDetail = "SkillCoursePurchaseSuccessModal-module--itemDetail--25f1f";
export var lmsScrollbar = "SkillCoursePurchaseSuccessModal-module--lms-scrollbar--f2e14";
export var planDetails = "SkillCoursePurchaseSuccessModal-module--planDetails--649cf";
export var planItem = "SkillCoursePurchaseSuccessModal-module--planItem--08c79";
export var portalBtn = "SkillCoursePurchaseSuccessModal-module--portalBtn--014c3";
export var price = "SkillCoursePurchaseSuccessModal-module--price--e5645";
export var purchaseDetails = "SkillCoursePurchaseSuccessModal-module--purchaseDetails--b63c5";
export var successDetails = "SkillCoursePurchaseSuccessModal-module--successDetails--ea1bf";
export var successDetailsInner = "SkillCoursePurchaseSuccessModal-module--successDetailsInner--35e8f";
export var title = "SkillCoursePurchaseSuccessModal-module--title--1ede9";
export var totalAmount = "SkillCoursePurchaseSuccessModal-module--totalAmount--b8e03";
export var totalContent = "SkillCoursePurchaseSuccessModal-module--totalContent--f5ccd";