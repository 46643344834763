import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { variants } from "../../../utils/variants";
import { CustomButton } from "../../adaptors/CustomButton";
import {
  checkUserHasFreePlan,
  checkUserHasSubscribedToPlan,
  sortPlansAcend,
} from "../../../utils/helpers/component";
import {
  HAS_FREE_PLAN,
  HAS_SUBSCRIBED_TO_PLAN,
  LMS_PLATFORM_OUT_LINK,
} from "../../../utils/localization";
import {
  cartDetailsActions,
  helperActions,
  modalActions,
} from "../../../utils/redux/actions";
import { bindActionCreators } from "redux";
import { useMutation } from "@apollo/client";
import { ADD_CART_ITEM, RESET_CART } from "../../../../graphql/mutations/cart";
import { useMemo } from "react";
import { isEmpty } from "lodash";

const SkillCoursePurchaseButton = (props) => {
  const { customClass, customTextClass } = props;
  const [buttonTitle, setButtonTitle] = useState("");
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const dispatch = useDispatch();
  const [resetCart] = useMutation(RESET_CART);
  const [addItemToCart] = useMutation(ADD_CART_ITEM);
  const { openPlansModal, closePlansModal } = bindActionCreators(
    modalActions,
    dispatch
  );
  const { setCartDetails } = bindActionCreators(cartDetailsActions, dispatch);
  const { openToastMessage } = bindActionCreators(helperActions, dispatch);
  const { userPlans, user, cartDetails } = useSelector((state) => {
    return state;
  });

  const sortedPlans = useMemo(
    () => sortPlansAcend([...userPlans.plans]),
    [userPlans]
  );

  const handleAddPlanToCart = async ({ cartDetails, plan }) => {
    try {
      const { data } = await addItemToCart({
        variables: cartDetails?.uuid
          ? {
              itemableType: "plan",
              itemableId: plan.id,
              cartUuid: cartDetails?.uuid,
            }
          : {
              itemableType: "plan",
              itemableId: plan.id,
            },
      });
      setCartDetails(data.cartItemAdd);
      setAddToCartLoading(false);
      openPlansModal();
    } catch (error) {
      setAddToCartLoading(false);
      console.log(error);
      const gqlError = error.graphQLErrors[0];
      if (gqlError) openToastMessage({ variant: variants.error });
    }
  };

  const clearCartAndAddPlanToCart = async () => {
    try {
      if (cartDetails?.uuid) {
        await resetCart({
          variables: {
            input: {
              cartUuid: cartDetails.uuid,
            },
          },
        });
      }
      handleAddPlanToCart({
        cartDetails: cartDetails,
        plan: sortedPlans[sortedPlans.length - 1],
      });
    } catch (error) {
      setAddToCartLoading(false);
      console.log(error);
      const gqlError = error;
      if (gqlError) openToastMessage({ variant: variants.error });
    }
  };

  useEffect(() => {
    const userPlanSubscriptionStatus = checkUserHasSubscribedToPlan(
      user.userDetails
    );
    const userFreePlanStatus = checkUserHasFreePlan(userPlans.plans);
    const getButtonTitle = (subscriptionStatus, freePlanStatus) => {
      if (subscriptionStatus === HAS_SUBSCRIBED_TO_PLAN) {
        return "Explore Courses";
      } else if (freePlanStatus === HAS_FREE_PLAN) {
        return "Apply Now";
      } else {
        return "Apply Now";
      }
    };
    if (!isEmpty(userPlans.plans)) {
      setButtonTitle(
        getButtonTitle(userPlanSubscriptionStatus, userFreePlanStatus)
      );
    }
  }, [userPlans.plans, user.userDetails]);

  const handleSubscription = () => {
    if (!isEmpty(userPlans.plans)) {
      const userPlanSubscriptionStatus = checkUserHasSubscribedToPlan(
        user.userDetails
      );
      if (userPlanSubscriptionStatus === HAS_SUBSCRIBED_TO_PLAN) {
        return (window.location.href = `${LMS_PLATFORM_OUT_LINK}`);
      } else {
        setAddToCartLoading(true);
        clearCartAndAddPlanToCart();
      }
    }
  };

  return (
    <CustomButton
      title={buttonTitle}
      variant={variants.primaryButton}
      customClass={customClass}
      customTextClass={customTextClass}
      onClick={handleSubscription}
      loading={isEmpty(userPlans.plans) || addToCartLoading}
    />
  );
};

SkillCoursePurchaseButton.propTypes = {
  customClass: PropTypes.string,
  customTextClass: PropTypes.string,
};

export default SkillCoursePurchaseButton;
