// extracted by mini-css-extract-plugin
export var active = "Course-module--active--700dd";
export var categories = "Course-module--categories--2e74d";
export var categoryName = "Course-module--categoryName--42d85";
export var container = "Course-module--container--7bb46";
export var controlButtons = "Course-module--controlButtons--036c5";
export var courseCarousel = "Course-module--courseCarousel--2dace";
export var coursesCarouselDesktop = "Course-module--coursesCarouselDesktop--12e27";
export var coursesCarouselMobile = "Course-module--coursesCarouselMobile--eb33d";
export var coursesContentDesktop = "Course-module--coursesContentDesktop--10057";
export var coursesContentMobile = "Course-module--coursesContentMobile--a8e62";
export var lmsScrollbar = "Course-module--lms-scrollbar--3f3ca";
export var searchContainer = "Course-module--searchContainer--3b883";
export var title = "Course-module--title--c59ce";
export var wrapper = "Course-module--wrapper--95a81";