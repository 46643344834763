import { Col, Typography } from "antd";
import React from "react";

import * as classes from "./SkillCoursePurchaseSuccessModal.module.scss";
import {
  IMAGE_PLACEHOLDER,
  LMS_PLATFORM_OUT_LINK,
  SKILL_COURSES_LANDING_PAGE_FILES_PATH,
} from "../../../utils/localization";
import { useSelector } from "react-redux";

const { Text, Title, Paragraph } = Typography;

const SkillCoursePurchaseSuccessModalContent = () => {
  const { userPlans } = useSelector((state) => {
    return state;
  });

  const bill = userPlans?.planPurchaseBill;

  return (
    <Col className={classes.successDetails}>
      <Col className={classes.successDetailsInner}>
        <img
          src={`${SKILL_COURSES_LANDING_PAGE_FILES_PATH}/creative-tick.svg`}
          alt="Unschool Skill Courses"
        />
        <h3>Subscription Successful!</h3>
        <p>
          Thank you for signing up with Unschool. You can access your courses
          through the learning portal.
        </p>
        <a href={LMS_PLATFORM_OUT_LINK}>
          <button className={classes.portalBtn}>GO TO LEARNING PORTAL</button>
        </a>
      </Col>
      <Col className={classes.purchaseDetails}>
        <Col className={classes.bill}>
          <Title className={classes.billTitle}>Bill Details</Title>
          <Col className={classes.billItem}>
            <Text className={classes.billContent}>Subtotal</Text>
            <Text className={classes.billAmount}>₹ {bill?.subTotalAmount}</Text>
          </Col>
          <Col className={classes.billItem}>
            <Text className={classes.billContent}>Total Discount</Text>
            <Text className={classes.billAmount}>₹ {bill?.discountAmount}</Text>
          </Col>
          <Col className={classes.billItem}>
            <Text className={classes.billContent}>GST 18%</Text>
            <Text className={classes.billAmount}>₹ {bill?.taxAmount}</Text>
          </Col>
          <Col className={classes.billTotal}>
            <Text className={classes.totalContent}>Items total</Text>
            <Text className={classes.totalAmount}>₹ {bill?.totalAmount}</Text>
          </Col>
          {bill.coupon && (
            <Col className={classes.billItem}>
              <Text className={classes.billContent}>Coupon Used</Text>
              <Text className={classes.billAmount}>{bill?.coupon}</Text>
            </Col>
          )}
        </Col>
        <Col className={classes.planDetails}>
          <Title>Subscription Details</Title>
          <Col className={classes.planItem}>
            <img src={IMAGE_PLACEHOLDER} alt="Unschool Skill Courses" />
            <Col className={classes.itemDetail}>
              <Paragraph className={classes.title}>
                {bill?.cartItems[0]?.itemable?.title}
              </Paragraph>
              <Paragraph className={classes.price}>
                ₹ {bill.cartItems[0].totalAmount}
              </Paragraph>
            </Col>
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default SkillCoursePurchaseSuccessModalContent;
