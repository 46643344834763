// extracted by mini-css-extract-plugin
export var LoadingContainer = "SkillCoursePlansModalContent-module--LoadingContainer--afdf0";
export var appliedCoupon = "SkillCoursePlansModalContent-module--appliedCoupon--763a4";
export var appliedCouponIcon = "SkillCoursePlansModalContent-module--appliedCouponIcon--4096d";
export var appliedCouponName = "SkillCoursePlansModalContent-module--appliedCouponName--15d2f";
export var appliedCouponText = "SkillCoursePlansModalContent-module--appliedCouponText--d3ad9";
export var applyCouponBtn = "SkillCoursePlansModalContent-module--applyCouponBtn--c70f5";
export var applyCouponBtnTextClass = "SkillCoursePlansModalContent-module--applyCouponBtnTextClass--c0280";
export var billAmount = "SkillCoursePlansModalContent-module--billAmount--5ba49";
export var billContent = "SkillCoursePlansModalContent-module--billContent--d2798";
export var billDetails = "SkillCoursePlansModalContent-module--billDetails--a05f2";
export var billItem = "SkillCoursePlansModalContent-module--billItem--3e0f0";
export var billTitle = "SkillCoursePlansModalContent-module--billTitle--13bc2";
export var billTotal = "SkillCoursePlansModalContent-module--billTotal--edff9";
export var couponForm = "SkillCoursePlansModalContent-module--couponForm--d110f";
export var couponInput = "SkillCoursePlansModalContent-module--couponInput--03680";
export var formItemLeft = "SkillCoursePlansModalContent-module--formItemLeft--5dd56";
export var formItemRight = "SkillCoursePlansModalContent-module--formItemRight--7a9ca";
export var lmsScrollbar = "SkillCoursePlansModalContent-module--lms-scrollbar--b81cc";
export var plansContainer = "SkillCoursePlansModalContent-module--plansContainer--619f2";
export var plansModalContainer = "SkillCoursePlansModalContent-module--plansModalContainer--451bb";
export var subscribeButton = "SkillCoursePlansModalContent-module--subscribeButton--d38c1";
export var subscribeButtonTextClass = "SkillCoursePlansModalContent-module--subscribeButtonTextClass--9439b";
export var totalAmount = "SkillCoursePlansModalContent-module--totalAmount--ecfde";
export var totalContent = "SkillCoursePlansModalContent-module--totalContent--e4db2";