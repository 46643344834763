// extracted by mini-css-extract-plugin
export var bottom = "LearningRoadMap-module--bottom--c0735";
export var buttonClassName = "LearningRoadMap-module--buttonClassName--fb4b8";
export var buttonCustomClass = "LearningRoadMap-module--buttonCustomClass--2365b";
export var buttonCustomTextClass = "LearningRoadMap-module--buttonCustomTextClass--e4e8d";
export var container = "LearningRoadMap-module--container--92007";
export var controlButtons = "LearningRoadMap-module--controlButtons--0fda1";
export var flowCurve = "LearningRoadMap-module--flowCurve--f3db2";
export var gifWrapper = "LearningRoadMap-module--gifWrapper--0d1df";
export var left = "LearningRoadMap-module--left--1196c";
export var lmsScrollbar = "LearningRoadMap-module--lms-scrollbar--184b5";
export var middle = "LearningRoadMap-module--middle--014bd";
export var right = "LearningRoadMap-module--right--69bdf";
export var slideContent = "LearningRoadMap-module--slideContent--07b2b";
export var top = "LearningRoadMap-module--top--e1962";
export var wrapperDesktop = "LearningRoadMap-module--wrapperDesktop--1800a";
export var wrapperMobile = "LearningRoadMap-module--wrapperMobile--49967";