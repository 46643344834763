import React from "react";

import * as classes from "./DividerContainer.module.scss";
import SkillCoursePurchaseButton from "../SkillCoursePurchaseButton";
import "../styles/divider.css";

const ImageDividerone = ({ item }) => {
  return (
    <div className="ImageOneContainer">
      <div className={item.background}>
        <div className="ImageDividerInner">
          <h3>{item.title}</h3>
          <p>{item.subtitle}</p>
          <SkillCoursePurchaseButton
            customClass={classes.customClass}
            customTextClass={classes.customTextClass}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageDividerone;
