import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import * as classes from "../../styles/skill-courses.module.scss";
import "../../components/skillCourseComponents/styles/style.css";
import PoweredBy from "../../components/skillCourseComponents/Poweredby";
import Courses from "../../components/skillCourseComponents/Courses";
import DividerContainer from "../../components/skillCourseComponents/DividerContainer/DividerContainer";
import Coaches from "../../components/skillCourseComponents/Coaches";
import Business from "../../components/skillCourseComponents/Business";
import Faq from "../../components/skillCourseComponents/Faq";
import LayoutWithOutPadding from "../../components/LayoutWithOutPadding";
import Hero from "../../components/skillCourseComponents/Hero";
import { useCategoryCourseList } from "../../components/skillCourseComponents/hooks/useCategoryCourseList";
import FooterCourseCategories from "../../components/skillCourseComponents/FooterCourseCategories";
import LearningRoadMap from "../../components/skillCourseComponents/LearningRoadMap";
import Reviews from "../../components/skillCourseComponents/Reviews";
import Stats from "../../components/skillCourseComponents/Stats";
import SkillCoursePurchaseSuccessModalContent from "../../components/skillCourseComponents/SkillCoursePurchaseSuccessModalContent";
import SkillCoursePurchaseButton from "../../components/skillCourseComponents/SkillCoursePurchaseButton";
import { CustomModal } from "../../components/CustomModal";
import { modalActions } from "../../utils/redux/actions";
import SkillCoursePlansModalContent from "../../components/skillCourseComponents/SkillCoursePlansModalContent";
import { isSSR } from "../../helpers/global";

export default function SKillCourses() {
  const { categoryCourseList } = useCategoryCourseList();
  const [showButton, setShowButton] = useState(
    isSSR() ? false : window.screen.width < 600 ? true : false
  );
  const dispatch = useDispatch();

  const { closePlansModal } = bindActionCreators(modalActions, dispatch);

  const { modals } = useSelector((state) => state);

  useEffect(() => {
    const scrolling = () => {
      if (window.scrollY > 100) {
        return setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    if (!isSSR() && window.screen.width > 600) {
      window.addEventListener("scroll", scrolling);
    }
  }, []);

  return (
    <LayoutWithOutPadding>
      <Helmet>
        <title>India’s Best Free E-Learning Library | Unschool</title>
        <meta
          name="description"
          content="Introducing 'OTT for learning. Explore the E-Learning library with 500+ courses and still counting. Start your 7 days free trial now!"
        />
        <link
          rel="apple-touch-icon"
          sizes="256x256"
          href="https://www.unschool.in/img/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="https://www.unschool.in/img/apple-touch-icon.png"
          sizes="256x256"
        />
      </Helmet>
      <Hero />
      <PoweredBy />
      <Courses categoryCourseList={categoryCourseList} />
      <LearningRoadMap />
      <Coaches />
      <Stats />
      <DividerContainer />
      <Business />
      <Reviews />
      <Faq />
      <FooterCourseCategories categoryCourseList={categoryCourseList} />
      {showButton && (
        <aside className={classes.floatButton}>
          <SkillCoursePurchaseButton
            customClass={classes.customClass}
            customTextClass={classes.customTextClass}
          />
        </aside>
      )}
      <CustomModal
        visible={modals.skillCoursePurchaseSuccess}
        onCancel={() => {
          window.location.reload();
        }}
        width="fit-content"
      >
        <SkillCoursePurchaseSuccessModalContent />
      </CustomModal>
      <CustomModal
        visible={modals.plansModal}
        onCancel={() => {
          closePlansModal();
        }}
        centered={true}
        width="auto"
        maskStyle={{ background: "rgba(0, 0, 0, 0.45)" }}
        title="Select Plan"
      >
        <SkillCoursePlansModalContent />
      </CustomModal>
    </LayoutWithOutPadding>
  );
}
