// extracted by mini-css-extract-plugin
export var courseImage = "SearchCourses-module--courseImage--dc5b0";
export var courseImageContainer = "SearchCourses-module--courseImageContainer--62fc7";
export var courseResultListContainer = "SearchCourses-module--courseResultListContainer--0a673";
export var courseResultListitem = "SearchCourses-module--courseResultListitem--459ed";
export var courseTitle = "SearchCourses-module--courseTitle--66be6";
export var lmsScrollbar = "SearchCourses-module--lms-scrollbar--5e0f6";
export var searchCourses = "SearchCourses-module--searchCourses--dc37b";
export var searchCoursesInputContainer = "SearchCourses-module--searchCoursesInputContainer--11f8e";
export var searchIcon = "SearchCourses-module--searchIcon--2c9a3";
export var searchResultLable = "SearchCourses-module--searchResultLable--40b20";