import React from "react";
import { Col } from "antd";
import PropTypes from "prop-types";

import * as classes from "./CourseCard.module.scss";
import {
  IMAGE_PLACEHOLDER,
  INTERNSHIP_PROGRAM,
} from "../../utils/localization";

const CourseCard = (props) => {
  const { course } = props;

  const findSeniorCoach = (course) => {
    return course.coaches.filter(
      (coach) => coach.userType === "senior_coach"
    )[0];
  };

  const getCourseOutLink = (course) => {
    switch (course.productLine) {
      case INTERNSHIP_PROGRAM:
        return `/internship-program/${course.slug}`;
      default:
        return `/skill-courses/${course.slug}`;
    }
  };

  return (
    <Col className={classes.courseCard}>
      <img src={course?.coverImage?.url || IMAGE_PLACEHOLDER} />
      <Col className={classes.courseDetails}>
        <h3>{course.title}</h3>
        <h3>{findSeniorCoach(course)?.firstName}</h3>
      </Col>
      <a href={getCourseOutLink(course)} target="_blank">
        <Col className={classes.knowMore}>Know More&nbsp;&nbsp;&nbsp; &gt;</Col>
      </a>
    </Col>
  );
};

CourseCard.propTypes = {
  course: PropTypes.object,
};

export default CourseCard;
