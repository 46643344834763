import React from "react";
import { Col } from "antd";

import * as classes from "./Poweredby.module.scss";
import { brands } from "../Utils/data";
import { flatten } from "lodash";

const Marquee =
  typeof window !== `undefined`
    ? require("react-simple-marquee").default
    : null;

const Poweredby = () => {
  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        <h1>Trusted, backed, and powered by</h1>
        <Col className={classes.brandsDesktop}>
          {brands.map((brand) => (
            <img
              key={brand.id}
              src={brand.image}
              alt="Unschool Skill Courses"
            />
          ))}
        </Col>
        <Col className={classes.brandsMobile}>
          <Marquee>
            {flatten(Array(100).fill(brands)).map((brand, index) => (
              <img key={index} src={brand.image} alt="Unschool Skill Courses" />
            ))}
          </Marquee>
        </Col>
      </Col>
    </Col>
  );
};

export default Poweredby;
