import React, { useEffect, useRef, useState } from "react";
import { Col } from "antd";

import * as classes from "./LearningRoadMap.module.scss";
import { variants } from "../../../utils/variants";
import { CustomButton } from "../../adaptors/CustomButton";
import Slider from "react-slick";
import { HOME_LANDING_PAGE_FILES_PATH } from "../../../utils/localization";
import { ReactSlickController } from "../../adaptors/ReactSlickController";

const LearningRoadMap = () => {
  const ref = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const [buttonsController, setButtonController] = useState({
    next: () => {},
    prev: () => {},
    slickGoTo: () => {},
    slidesToShow: null,
  });

  const settings = {
    dots: false,
    arrows: false,
    centerMode: true,
    slidesToShow: 1,
    speed: 500,
    rows: 1,
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
  };

  useEffect(() => {
    const slickController = { ...ref?.current };
    setButtonController((state) => ({
      ...state,
      next: slickController?.slickNext,
      prev: slickController?.slickPrev,
      slickGoTo: slickController?.slickGoTo,
      slidesToShow: slickController?.props?.slidesToShow,
    }));
  }, [ref]);

  return (
    <Col className={classes.container}>
      <Col className={classes.wrapperDesktop}>
        <Col>
          <Col className={classes.top}>
            <Col className={classes.left}>
              <h1>Self-Paced Learning</h1>
              <p>
                Learn at your own pace. You could earn higher grades because you
                can learn at your own pace. You'll benefit from a flexible
                schedule and not to forget access to course materials 24 hours a
                day, 7 days a week.
              </p>
              {/* <CustomButton
                title={"Learn at your comfort"}
                variant={variants.primaryButton}
                customClass={classes.buttonCustomClass}
                customTextClass={classes.buttonCustomTextClass}
              /> */}
            </Col>
            <Col className={classes.right}>
              <Col>
                <img
                  src={`${HOME_LANDING_PAGE_FILES_PATH}/self-faced-learning.gif`}
                  alt="Unschool Skill Courses"
                />
              </Col>
            </Col>
          </Col>
          <Col className={classes.middle}>
            <Col className={classes.left}>
              <Col>
                <img
                  src={`${HOME_LANDING_PAGE_FILES_PATH}/community.gif`}
                  alt="Unschool Skill Courses"
                />
              </Col>
            </Col>
            <Col className={classes.right}>
              <h1>Community Access</h1>
              <p>
                Know your peers. You'll network with classmates from a wide
                range of backgrounds and locations who are in the race to
                upskill.
              </p>
              {/* <CustomButton
                title={"Know your peers"}
                variant={variants.primaryButton}
                customClass={classes.buttonCustomClass}
                customTextClass={classes.buttonCustomTextClass}
              /> */}
            </Col>
          </Col>
          <Col className={classes.bottom}>
            <Col className={classes.left}>
              <h1>Get Certified</h1>
              <p>
                You can learn from instructors across the country and around the
                world, broadening your perspective and receiving certification
                for your career growth.
              </p>
              {/* <CustomButton
                title={"Enroll Now"}
                variant={variants.primaryButton}
                customClass={classes.buttonCustomClass}
                customTextClass={classes.buttonCustomTextClass}
              /> */}
            </Col>
            <Col className={classes.right}>
              <Col>
                <img
                  src={`${HOME_LANDING_PAGE_FILES_PATH}/certificate.gif`}
                  alt="Unschool Skill Courses"
                />
              </Col>
            </Col>
          </Col>
        </Col>
        <img
          src={`${HOME_LANDING_PAGE_FILES_PATH}/flow-curve.png`}
          className={classes.flowCurve}
          alt="Unschool Skill Courses"
        />
      </Col>
      <Col className={classes.wrapperMobile}>
        <Slider ref={ref} {...settings}>
          <Col className={classes.slideContent}>
            <h1>Self-Paced Learning</h1>
            <p>
              Learn at your own pace. You could earn higher grades because you
              can learn at your own pace. You'll benefit from a flexible
              schedule and not to forget access to course materials 24 hours a
              day, 7 days a week.
            </p>
            <Col className={classes.gifWrapper}>
              <img
                src={`${HOME_LANDING_PAGE_FILES_PATH}/self-faced-learning.gif`}
                alt="Unschool Skill Courses"
              />
            </Col>
            {/* <Col>
              <CustomButton
                title={"Learn at your comfort"}
                variant={variants.primaryButton}
                customClass={classes.buttonCustomClass}
                customTextClass={classes.buttonCustomTextClass}
              />
            </Col> */}
          </Col>
          <Col className={classes.slideContent}>
            <h1>Community Access</h1>
            <p>
              Know your peers. You'll network with classmates from a wide range
              of backgrounds and locations who are in the race to upskill.
            </p>
            <Col className={classes.gifWrapper}>
              <img
                src={`${HOME_LANDING_PAGE_FILES_PATH}/community.gif `}
                alt="Unschool Skill Courses"
              />
            </Col>
            {/* <Col>
              <CustomButton
                title={"Know your peers"}
                variant={variants.primaryButton}
                customClass={classes.buttonCustomClass}
                customTextClass={classes.buttonCustomTextClass}
              />
            </Col> */}
          </Col>
          <Col className={classes.slideContent}>
            <h1>Get Certified</h1>
            <p>
              You can learn from instructors across the country and around the
              world, broadening your perspective and receiving certification for
              your career growth.
            </p>
            <Col className={classes.gifWrapper}>
              <img
                src={`${HOME_LANDING_PAGE_FILES_PATH}/certificate.gif`}
                alt="Unschool Skill Courses"
              />
            </Col>
            {/* <Col>
              <CustomButton
                title={"Enroll Now"}
                variant={variants.primaryButton}
                customClass={classes.buttonCustomClass}
                customTextClass={classes.buttonCustomTextClass}
              />
            </Col> */}
          </Col>
        </Slider>
        <Col className={classes.controlButtons}>
          <ReactSlickController
            next={buttonsController.next}
            prev={buttonsController.prev}
            slickGoTo={buttonsController.slickGoTo}
            activeSlide={activeSlide}
            totalSlides={3}
            slidesToShow={buttonsController.slidesToShow}
            buttonClassName={classes.buttonClassName}
          />
        </Col>
      </Col>
    </Col>
  );
};

export default LearningRoadMap;
