import React from "react";
import { Col } from "antd";

import * as classes from "./Reviews.module.scss";

const ReviewCard = (props) => {
  const { review } = props;

  return (
    <Col className={classes.reviewCard}>
      <Col className={classes.reviewCardTop}>
        <img src={review.image} alt="Unschool Skill Courses" />
        <img src={review.companyLogo} alt="Unschool Skill Courses" />
      </Col>
      <Col className={classes.reviewCardBottom}>
        <h1>{review.name}</h1>
        <p>{review.review}</p>
      </Col>
    </Col>
  );
};

export default ReviewCard;
