import React from "react";
import { Col } from "antd";

import * as classes from "./Hero.module.scss";
import { CustomButton } from "../../adaptors/CustomButton";
import { variants } from "../../../utils/variants";

const Hero = () => {
  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        <Col className={classes.content}>
          <Col>
            <h1>
              India’s First Streaming <br /> Platform for Learning
            </h1>
            <p>
              A platform to learn new things, upskill your career,
              <br /> and explore your interests.
            </p>
            <Col className={classes.buttonWrapper}>
              <a href="#skill-courses-browse">
                <CustomButton
                  title={"Get Started"}
                  variant={variants.primaryButton}
                  customClass={classes.buttonCustomClass}
                  customTextClass={classes.buttonCustomTextClass}
                />
              </a>
            </Col>
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default Hero;
